import React, { useEffect, useState } from "react"
import {
  motion,
  useAnimation,
  useMotionValue,
  useMotionValueEvent,
  useScroll,
} from "framer-motion"
import { Grid, Stack, styled, Typography, Container } from "@mui/material"

const MainDiv = styled(motion.div)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  justifyContent: "flex-start",
  alignContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  position: "fixed",
  top: 0,
  width: "100%",
  backgroundColor: theme.palette.secondary.main,
  height: "80px",
  zIndex: 999,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: "space-between",
    height: "52px",
  },
}))

const NavBar = () => {
  const { scrollY } = useScroll()
  const [hidden, setHidden] = useState(false)
  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious()
    if (latest > previous && latest > 150) {
      setHidden(true)
    } else {
      setHidden(false)
    }
  })

  return (
    <MainDiv
      variants={{
        visible: { y: 0 },
        hidden: { y: "-80px" },
      }}
      animate={hidden ? "hidden" : "visible"}
      transition={{ duration: 0.35, ease: [0.6, 0.01, 0.05, 0.95] }}
    >
      <Typography variant="h5" color={"primary"}>
        Daniel Billingham
      </Typography>
      <Typography
        variant="h5"
        color={"primary"}
        sx={{
          fontStyle: "italic",
          fontFamily: "serif",
        }}
      >
        Creative
      </Typography>
    </MainDiv>
  )
}

export default NavBar
