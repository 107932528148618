import React from "react"
import { Grid, styled, useTheme } from "@mui/material"
import WorkGridThumbnail from "./WorkGridThumbnail"
import { useQuery } from "@apollo/client"
import GET_WORKS_THUMBNAILS from "../../../Queries/getWorksThumbnails"
import { motion } from "framer-motion"

type ServiceWorkType = {
  sys: {
    id: string
  }
  serviceList: string
}

type WorkType = {
  id: string
  title: string
  description: string
  heroMedia: {
    url: string
  }
  serviceWorkCollection?: {
    items: ServiceWorkType[]
  }
}

type WorkGridProps = {
  works?: WorkType[]
  onWorkClick?: (work: WorkType) => void
}

const MainContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,

  [theme.breakpoints.up("xs")]: {
    paddingTop: theme.spacing(4) + "!important",
    paddingBottom: theme.spacing(4) + "!important",
    paddingLeft: theme.spacing(2) + "!important",
    paddingRight: theme.spacing(2) + "!important",
  },
  // Styles for sm and above
  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(4) + "!important",
    paddingBottom: theme.spacing(4) + "!important",
    paddingLeft: theme.spacing(3) + "!important",
    paddingRight: theme.spacing(3) + "!important",
  },
  // Styles for md and above
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(5) + "!important",
    paddingBottom: theme.spacing(5) + "!important",
    paddingLeft: theme.spacing(4) + "!important",
    paddingRight: theme.spacing(4) + "!important",
  },
  // Styles for lg and above
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(6) + "!important",
    paddingBottom: theme.spacing(6) + "!important",
    paddingLeft: theme.spacing(5) + "!important",
    paddingRight: theme.spacing(5) + "!important",
  },
  // Styles for xl and above
  [theme.breakpoints.up("xl")]: {
    paddingTop: theme.spacing(16) + "!important",
    paddingBottom: theme.spacing(16) + "!important",
    paddingLeft: theme.spacing(12) + "!important",
    paddingRight: theme.spacing(12) + "!important",
  },
}))

const GridItem = styled(motion(Grid))(({ theme }) => ({
  alignItems: "flex-start",
  [theme.breakpoints.down("sm")]: {},
}))

const GridItemSpacer = styled(motion(Grid))(({ theme }) => ({
  [theme.breakpoints.down("sm")]: { display: "none" },
}))

const WorkGrid: React.FC<WorkGridProps> = ({ onWorkClick }) => {
  const { loading, error, data } = useQuery(GET_WORKS_THUMBNAILS)

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  const works = data?.workCollection.items.map((item: any) => ({
    id: item.sys.id,
    title: item.title,
    description: item.description,
    heroMedia: {
      url: `${item.heroMedia.url}`,
    },
    serviceWorkCollection: item.serviceWorkCollection, // Ensure this line is correct JUST ADDED
  }))

  return (
    <MainContainer
      container
      spacing={0}
      rowSpacing={0}
      alignItems="flex-start"
      sx={{
        "& .MuiGrid-item": {
          pt: { xs: 3, md: 4, lg: 8 }, // pt is for padding-top which corresponds to rowSpacing
        },
      }}
    >
      {/* Row One */}
      <GridItem item xs={12} md={6}>
        <WorkGridThumbnail
          work={works[0]}
          onClick={() => onWorkClick?.(works[0])}
        />
      </GridItem>
      <GridItemSpacer item md={2} />
      <GridItem item xs={12} md={4}>
        <WorkGridThumbnail
          work={works[1]}
          onClick={() => onWorkClick?.(works[1])}
        />
      </GridItem>

      {/* Row Two */}

      <GridItem item xs={12} md={4}>
        <WorkGridThumbnail
          work={works[2]}
          onClick={() => onWorkClick?.(works[2])}
        />
      </GridItem>
      <GridItemSpacer item md={1} />
      <GridItem item xs={12} md={6}>
        <WorkGridThumbnail
          work={works[3]}
          onClick={() => onWorkClick?.(works[3])}
        />
      </GridItem>
      <GridItemSpacer item md={1} />

      {/* Row Three */}
      <GridItemSpacer item md={1} />
      <GridItem item xs={12} md={6}>
        <WorkGridThumbnail
          work={works[4]}
          onClick={() => onWorkClick?.(works[4])}
        />
      </GridItem>
      <GridItemSpacer item md={2} />
      <GridItem item xs={12} md={3}>
        <WorkGridThumbnail
          work={works[5]}
          onClick={() => onWorkClick?.(works[5])}
        />
      </GridItem>
      {/* Row Four */}
      <GridItem item xs={12} md={6}>
        <WorkGridThumbnail
          work={works[6]}
          onClick={() => onWorkClick?.(works[6])}
        />
      </GridItem>
      <GridItemSpacer item md={2} />
      <GridItem item xs={12} md={4}>
        <WorkGridThumbnail
          work={works[7]}
          onClick={() => onWorkClick?.(works[7])}
        />
      </GridItem>
    </MainContainer>
  )
}

export default WorkGrid
