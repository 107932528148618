import React from "react"
import logo from "./logo.svg"
import "./App.css"
import HomePage from "./Pages/Home/HomePage"

import NavBar from "./Navigation/NavBar"
import { ApolloProvider } from "@apollo/client"
import client from "./Services/apolloClient"
import { ThemeProvider } from "@mui/material/styles"
import theme from "./theme"
import Helmet from "react-helmet"

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-L1ZC07HGFX"
          ></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-L1ZC07HGFX');
            `}
          </script>
        </Helmet>
        <div className="App">
          <NavBar />
          <HomePage />
        </div>
      </ApolloProvider>
    </ThemeProvider>
  )
}

// function App() {
//   return (
//     <ThemeProvider theme={theme}>
//       <ApolloProvider client={client}>
//         <div className="App">
//           <NavBar></NavBar>
//           <HomePage></HomePage>
//         </div>
//       </ApolloProvider>
//     </ThemeProvider>
//   )
// }

export default App
