import { useMediaQuery, useTheme } from "@mui/material"

const imageSizeMap = {
  xsmall: 500,
  small: 900,
  medium: 470,
  large: 850,
  xlarge: 1440,
}

export const useResponsiveImageHistory = () => {
  const theme = useTheme()
  const isXSmall = useMediaQuery(theme.breakpoints.only("xs"))
  const isSmall = useMediaQuery(theme.breakpoints.only("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.only("md"))
  const isLarge = useMediaQuery(theme.breakpoints.only("lg"))
  const isXLarge = useMediaQuery(theme.breakpoints.only("xl"))

  // Determine the selected screen size for images
  const screenSize = isXSmall
    ? "xsmall"
    : isSmall
    ? "small"
    : isMedium
    ? "medium"
    : isLarge
    ? "large"
    : "xlarge"

  return imageSizeMap[screenSize]
}
