import { createTheme } from "@mui/material/styles"
import { CSSProperties } from "@mui/material/styles/createTypography"

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true // retain the `xs` breakpoint
    sm: true // retain the `sm` breakpoint
    md: true // retain the `md` breakpoint
    lg: true // retain the `lg` breakpoint
    xl: true // retain the `xl` breakpoint
    xxl: true // add the `xxl` breakpoint
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    jumbo: CSSProperties
    intro: CSSProperties
  }

  interface TypographyVariantsOptions {
    jumbo?: CSSProperties
    intro?: CSSProperties
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    jumbo: true
    intro: true
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // Extra small devices
      sm: 600, // Small devices
      md: 900, // Medium devices
      lg: 1200, // Large devices
      xl: 1536, // Extra large devices
      xxl: 1920, // Extra extra large devices
    },
  },
  palette: {
    primary: {
      // main: "#FF757D", // Peach
      // main: "#93c3be", // green
      // main: "#e1ed1d", // Yellow
      main: "#fcfed8", // off white
      dark: "#121212",
    },
    secondary: {
      // main: "#020101", // Black
      main: "#121212", // Light Black
      light: "#ffffff",
      dark: "#1f1f1f",
    },
    text: {
      primary: "#121212", // Light Grey
      secondary: "#ffffff", // White
      disabled: "#BDBDBD", // Text color for disabled content
    },
  },
  typography: {
    fontFamily: [
      // 'Outfit',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    jumbo: {
      letterSpacing: "11",
      // Existing breakpoints
      "@media (max-width:600px)": {
        fontSize: "8.5rem",
        lineHeight: "7.5rem",
      },
      "@media (min-width:600px) and (max-width:900px)": {
        fontSize: "14.5rem",
        lineHeight: "12.5rem",
      },
      "@media (min-width:900px) and (max-width:1200px)": {
        fontSize: "16.5rem",
        lineHeight: "14rem",
      },
      "@media (min-width:1200px) and (max-width:1536px)": {
        fontSize: "24.5rem",
        lineHeight: "20rem",
      },
      "@media (min-width:1536px) and (max-width:1920px)": {
        // Adjusted to include an upper limit for the 'lg' breakpoint
        fontSize: "22rem",
        lineHeight: "18rem",
      },
      // New XXL breakpoint for very large screens
      "@media (min-width:1920px)": {
        fontSize: "28rem", // Example larger font size for XXL screens
        lineHeight: "24rem", // Example larger line height for better readability
      },
    },

    h1: {
      fontSize: "18rem",
      lineHeight: "18.5rem",
      letterSpacing: "11",
      "@media (max-width:600px)": {
        fontSize: "5rem", // 'xs' breakpoint
        lineHeight: "5.5rem",
      },
      "@media (min-width:600px) and (max-width:900px)": {
        fontSize: "9rem", // 'sm' breakpoint
        lineHeight: "9.5rem",
      },
      "@media (min-width:900px) and (max-width:1200px)": {
        fontSize: "12rem", // 'md' breakpoint
        lineHeight: "12.5rem",
      },
      "@media (min-width:1200px) and (max-width:1536px)": {
        fontSize: "15rem", // 'lg' breakpoint
        lineHeight: "15.5rem",
      },
      "@media (min-width:1536px) and (max-width:1920px)": {
        fontSize: "15rem", // overlapping 'lg' to 'xl'
        lineHeight: "15.5rem",
      },
      "@media (min-width:1920px)": {
        fontSize: "15rem", // 'xxl' breakpoint
        lineHeight: "15.5rem",
      },
    },

    h2: {
      "@media (max-width:600px)": {
        fontSize: "2.75rem", // 'xs' breakpoint
        lineHeight: "4.25rem",
      },
      "@media (min-width:600px) and (max-width:900px)": {
        fontSize: "2.75rem", // 'sm' breakpoint
        lineHeight: "4.25rem",
      },
      "@media (min-width:900px) and (max-width:1200px)": {
        fontSize: "3rem", // 'md' breakpoint
        lineHeight: "4.5rem",
      },
      "@media (min-width:1200px) and (max-width:1536px)": {
        fontSize: "3.5rem", // 'lg' breakpoint
        lineHeight: "5rem",
      },
      "@media (min-width:1536px) and (max-width:1920px)": {
        fontSize: "4rem", // overlapping 'lg' to 'xl'
        lineHeight: "5.5rem",
      },
      "@media (min-width:1920px)": {
        fontSize: "4.5rem", // 'xxl' breakpoint
        lineHeight: "6rem",
      },
    },
    intro: {
      "@media (max-width:600px)": {
        fontSize: "1.25rem", // 'xs' breakpoint
        lineHeight: "2rem",
      },
      "@media (min-width:600px) and (max-width:900px)": {
        fontSize: "1.25rem", // 'sm' breakpoint
        lineHeight: "2rem",
      },
      "@media (min-width:900px) and (max-width:1200px)": {
        fontSize: "2rem", // 'md' breakpoint
        lineHeight: "3rem",
      },
      "@media (min-width:1200px) and (max-width:1536px)": {
        fontSize: "2.5rem", // 'lg' breakpoint
        lineHeight: "3.5rem",
      },
      "@media (min-width:1536px) and (max-width:1920px)": {
        fontSize: "2rem", // overlapping 'lg' to 'xl'
        lineHeight: "3rem",
      },
      "@media (min-width:1920px)": {
        fontSize: "2.5rem", // 'xxl' breakpoint
        lineHeight: "3.5rem",
      },
    },
  },
  // ... other theme configurations ...
})

export default theme
