type AnimationProps = {
  initial: {
    y: number
    opacity: number
  }
  animate: {
    y: number
    opacity: number
    transition: {
      ease: number[]
      duration: number
      delay: number
    }
  }
}

export const scaleOnHover = (delay: number): AnimationProps => ({
  initial: {
    y: 10,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 2.3,
      delay: delay,
    },
  },
})

type HeroAnimationProps = {
  heroinitial: {
    y: number
    opacity: number
  }
  heroanimate: {
    y: number
    opacity: number
    transition: {
      ease: number[]
      duration: number
      delay: number
    }
  }
}

export const HeroScaleAnimation = (delay: number): HeroAnimationProps => ({
  heroinitial: {
    y: 10,
    opacity: 0,
  },
  heroanimate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 0.8,
      delay: delay,
    },
  },
})
