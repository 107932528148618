import React, { useState, useRef, useEffect } from "react"
import { Container, DialogContent, styled } from "@mui/material"
import { useScroll } from "framer-motion"
import Intro from "./HomeComponents/Intro"
import WorkGrid from "./HomeComponents/WorkGrid"
import CustomDialog from "../WorkDetail/WorkDetailComponents/CustomDialog"
import WorkDetail from "../WorkDetail/WorkDetail"
import History from "./HomeComponents/History"
import HOne from "../../TextBlocks/HOneTextBlock"
import Foot from "../Footer/Foot"

const MainContainer = styled(Container)(({ theme }) => ({
  paddingLeft: theme.spacing(0) + "!important",
  paddingRight: theme.spacing(0) + "!important",
  backgroundColor: theme.palette.secondary.main,
  [theme.breakpoints.down("sm")]: {},
}))

const HomePage: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)

  const { scrollY } = useScroll({
    container: containerRef,
  })

  const [open, setOpen] = useState(false)
  const [selectedWork, setSelectedWork] = useState<any | null>(null)

  const handleOpen = (work: any) => {
    setSelectedWork(work)
    setOpen(true)

    // Google Analytics event tracking
    if (window.gtag) {
      window.gtag("event", "select_content", {
        content_type: "work",
        item_id: work.id,
        title: work.title,
      })
    }
  }

  const handleClose = () => {
    console.log("Dialog closing")
    setSelectedWork(null)
    setOpen(false)
  }

  return (
    <MainContainer maxWidth={false}>
      <Intro />
      <WorkGrid onWorkClick={handleOpen} />
      <CustomDialog open={open} onClose={handleClose} scroll="paper">
        <DialogContent ref={containerRef}>
          {selectedWork && (
            <WorkDetail
              id={selectedWork.id}
              onClose={handleClose}
              containerRef={containerRef}
            />
          )}
        </DialogContent>
      </CustomDialog>
      <History
        children={<HOne TitleOne={"Work"} TitleTwo={"History"}></HOne>}
      ></History>
      <Foot></Foot>
      {/* <Footer></Footer> */}
    </MainContainer>
  )
}

export default HomePage
