import { gql } from "@apollo/client"

const GET_FOOT = gql`
  query GetFoot {
    footCollection {
      items {
        sys {
          id
        }
        headerOne
        headerTwo
        headerThree
        headerFour
        bodyOne
        bodyTwo
        bodyThree
        bodyFour
        footImg {
          url
        }
      }
    }
  }
`

export default GET_FOOT
