import { gql } from "@apollo/client"

const GET_WORKS_THUMBNAILS = gql`
  query WorksThumbnails {
    workCollection(order: order_ASC) {
      items {
        sys {
          id
        }
        title
        heroMedia {
          url
        }
        serviceWorkCollection {
          items {
            sys {
              id
            }
            serviceList
          }
        }
      }
    }
  }
`

export default GET_WORKS_THUMBNAILS
