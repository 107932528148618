import { Typography, Grid, styled } from "@mui/material"
import { motion } from "framer-motion"
import { scaleOnHover } from "../Animation/AnimationOne"

const MainGridContainer = styled(Grid)(({ theme }) => ({
  // border: "4px solid red",
  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(3) + "!important",
    paddingBottom: theme.spacing(3) + "!important",
    paddingLeft: theme.spacing(0) + "!important",
    paddingRight: theme.spacing(0) + "!important",
  },
  // Styles for md and above
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(3) + "!important",
    paddingBottom: theme.spacing(3) + "!important",
    paddingLeft: theme.spacing(0) + "!important",
    paddingRight: theme.spacing(0) + "!important",
  },
  // Styles for lg and above
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(3) + "!important",
    paddingBottom: theme.spacing(3) + "!important",
    paddingLeft: theme.spacing(0) + "!important",
    paddingRight: theme.spacing(0) + "!important",
  },
  // Styles for xl and above
  [theme.breakpoints.up("xl")]: {
    paddingTop: theme.spacing(3) + "!important",
    paddingBottom: theme.spacing(3) + "!important",
    paddingLeft: theme.spacing(0) + "!important",
    paddingRight: theme.spacing(0) + "!important",
  },
}))

// adjust the indent of the headline,
const TextWrapper = styled(motion(Grid))(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  [theme.breakpoints.up("xs")]: {
    paddingLeft: theme.spacing(1.7),
  },
  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(2.4),
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(2.9),
  },
  [theme.breakpoints.up("lg")]: {
    paddingLeft: theme.spacing(2.7),
  },
  [theme.breakpoints.up("xl")]: {
    paddingLeft: theme.spacing(3.8),
  },
}))

interface HOneProps {
  TitleOne: string
  TitleTwo: string
}

const HOne: React.FC<HOneProps> = ({ TitleOne, TitleTwo }) => {
  return (
    <MainGridContainer container>
      <Grid
        item
        xs={12}
        md={12}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <motion.div initial="initial" animate="animate">
          <TextWrapper variants={scaleOnHover(0)}>
            <Typography
              variant="h1"
              fontWeight={900}
              align="left"
              color={"secondary.dark"}
            >
              {TitleOne}
            </Typography>
          </TextWrapper>
          <TextWrapper variants={scaleOnHover(0)}>
            <Typography
              variant="h1"
              fontWeight={900}
              align="left"
              color={"secondary.dark"}
            >
              {TitleTwo}
            </Typography>
          </TextWrapper>
        </motion.div>
      </Grid>
    </MainGridContainer>
  )
}

export default HOne
