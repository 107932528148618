// CustomizedAccordionComponents.jsx
import { styled, useTheme } from "@mui/material/styles"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"

export const CustomizedAccordion = styled(Accordion)({
  width: "100%",
  margin: 0,
  padding: 0,
  boxShadow: "none",
  backgroundColor: "transparent",
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: 0,
  },
})

export const CustomizedAccordionSummary = styled(AccordionSummary)({
  "&.MuiAccordionSummary-root": {
    margin: 0,
    padding: 0,
    minHeight: 0,
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      margin: 0,
    },
  },
})

export const CustomizedAccordionDetails = styled(AccordionDetails)(
  ({ theme }) => ({
    width: "100%",
    margin: 0,
    // padding:  "64px 0px 122px 0px !important",
    backgroundColor: "transparent",
    [theme.breakpoints.up("xs")]: {
      padding: "0px 0px  16px 0px ", // Padding for screens wider than sm
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px 0px  16px 0px ", // Padding for screens wider than sm
    },
    [theme.breakpoints.up("md")]: {
      padding: "48px 0px 96px 0px ", // Padding for screens wider than md
    },
    [theme.breakpoints.up("lg")]: {
      padding: "64px 0px 122px 0px", // Padding for screens wider than lg
    },
    // maxWidth: "80ch",
  })
)
