import { Typography, Grid, styled } from "@mui/material"
import { motion } from "framer-motion"
import { scaleOnHover } from "../../../Animation/AnimationOne"
import { useQuery, gql } from "@apollo/client"
import GET_INTRO from "../../../Queries/getIntro"

const MainGridContainer = styled(Grid)(({ theme }) => ({
  // border: "4px solid orange",
  // Styles for xs and above
  paddingTop: theme.spacing(16) + "!important",
  paddingBottom: theme.spacing(2) + "!important",
  paddingLeft: theme.spacing(0) + "!important",
  paddingRight: theme.spacing(0) + "!important",
  // Styles for sm and above
  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(16) + "!important",
    paddingBottom: theme.spacing(3) + "!important",
    paddingLeft: theme.spacing(0) + "!important",
    paddingRight: theme.spacing(0) + "!important",
  },
  // Styles for md and above
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(16) + "!important",
    paddingBottom: theme.spacing(4) + "!important",
    paddingLeft: theme.spacing(0) + "!important",
    paddingRight: theme.spacing(0) + "!important",
  },
  // Styles for lg and above
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(16) + "!important",
    paddingBottom: theme.spacing(5) + "!important",
    paddingLeft: theme.spacing(0) + "!important",
    paddingRight: theme.spacing(0) + "!important",
  },
  // Styles for xl and above
  [theme.breakpoints.up("xl")]: {
    paddingTop: theme.spacing(20) + "!important",
    paddingBottom: theme.spacing(20) + "!important",
    paddingLeft: theme.spacing(0) + "!important",
    paddingRight: theme.spacing(0) + "!important",
  },
}))

const TextWrapper = styled(motion(Grid))(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  whiteSpace: "nowrap",
  overflow: "hidden",
  flexDirection: "column",
  [theme.breakpoints.up("xs")]: {
    paddingLeft: theme.spacing(1.1),
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(1.6),
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(2.6),
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up("lg")]: {
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up("xl")]: {
    paddingLeft: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
}))

const SubheaderWrapper = styled(motion.div)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("xs")]: {
    paddingLeft: theme.spacing(2),
  },
  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(3),
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(4),
  },
  [theme.breakpoints.up("lg")]: {
    paddingLeft: theme.spacing(5),
  },
  [theme.breakpoints.up("xl")]: {
    paddingLeft: theme.spacing(12),
  },
}))

const BreakWrapper = styled(motion.div)(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.up("xs")]: {
    flexDirection: "column",
    paddingLeft: theme.spacing(0),
  },
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    gap: "6px",
  },
  [theme.breakpoints.up("md")]: {
    gap: "8px",
  },
  [theme.breakpoints.up("lg")]: {
    gap: "9px",
  },
  [theme.breakpoints.up("xl")]: {
    gap: "9px",
  },
}))

interface IntroProps {
  name: string
  surname: string
  header: string
  subheader: string
}

const Intro = () => {
  // Use the useQuery hook to fetch data
  const { data, loading, error } = useQuery(GET_INTRO)

  // Handle loading state
  if (loading) return <p>Loading...</p>

  // Handle error state
  if (error) return <p>Error: {error.message}</p>

  // Extract your data
  const {
    name,
    surname,
    header,
    subheader,
    subheaderTwo,
    subheaderThree,
    subheaderFour,
    subheaderFive,
    subheaderSix,
    subheaderSeven,
    subheaderEight,
  } = data.introCollection.items[0]

  return (
    <MainGridContainer container>
      <Grid
        item
        xs={12}
        md={12}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <motion.div initial="initial" animate="animate">
          <TextWrapper variants={scaleOnHover(0)}>
            <Typography
              variant="jumbo"
              fontWeight={900}
              align="left"
              color={"primary"}
            >
              Folio
              {/* {name} */}
            </Typography>
            <Typography
              variant="jumbo"
              fontWeight={600}
              align="left"
              color={"primary"}
            >
              24
              {/* {surname} */}
            </Typography>
          </TextWrapper>

          <SubheaderWrapper variants={scaleOnHover(1)}>
            <BreakWrapper>
              <Typography
                variant="intro"
                fontWeight={"regular"}
                align="left"
                color={"primary"}
              >
                {subheader}
              </Typography>
              <Typography
                variant="intro"
                fontWeight={"regular"}
                align="left"
                color={"primary"}
              >
                {subheaderTwo}
              </Typography>
            </BreakWrapper>
            <BreakWrapper>
              <Typography
                variant="intro"
                fontWeight={"regular"}
                align="left"
                color={"primary"}
              >
                {subheaderThree}
              </Typography>
              <Typography
                variant="intro"
                fontWeight={"regular"}
                align="left"
                color={"primary"}
              >
                {subheaderFour}
              </Typography>
            </BreakWrapper>
            <BreakWrapper>
              <Typography
                variant="intro"
                fontWeight={"regular"}
                align="left"
                color={"primary"}
              >
                {subheaderFive}
              </Typography>
              <Typography
                variant="intro"
                fontWeight={"regular"}
                align="left"
                color={"primary"}
              >
                {subheaderSix}
              </Typography>
            </BreakWrapper>
            <BreakWrapper>
              <Typography
                variant="intro"
                fontWeight={"regular"}
                align="left"
                color={"primary"}
              >
                {subheaderSeven}
              </Typography>
              <Typography
                variant="intro"
                fontWeight={"regular"}
                align="left"
                color={"primary"}
              >
                {subheaderEight}
              </Typography>
            </BreakWrapper>
          </SubheaderWrapper>
        </motion.div>
      </Grid>
    </MainGridContainer>
  )
}

export default Intro
