import React, { useEffect, useRef, useState } from "react"
import { motion, useInView } from "framer-motion"
import { useQuery, gql } from "@apollo/client"
import { Typography, Grid, styled, Box } from "@mui/material"
import { CONTENTFUL_DATA_QUERY } from "../../../Queries/queries"
import {
  CustomizedAccordion,
  CustomizedAccordionSummary,
  CustomizedAccordionDetails,
} from "../../../CustomMuiComponents/CustomizedAccordionComponents"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import theme from "../../../theme"
import { GET_HISTORY } from "../../../Queries/getHistory"
import { useResponsiveImageHistory } from "./useResponsiveImageHistory"

type HistoryProps = {
  children: React.ReactNode
}

interface ImgOneProps {
  background?: any
}

interface ImgTwoProps {
  background?: any
}

const OuterWrapperDiv = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up("xs")]: {
    paddingTop: theme.spacing(4) + "!important",
  },
  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(4) + "!important",
  },
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(5) + "!important",
  },
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(6) + "!important",
  },
  [theme.breakpoints.up("xl")]: {
    paddingTop: theme.spacing(16) + "!important",
  },
}))

const HeadlineWrapper = styled(motion.div)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    marginBottom: theme.spacing(2) + "!important",
  },
  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(3) + "!important",
  },
  [theme.breakpoints.up("md")]: {
    marginBottom: theme.spacing(4) + "!important",
  },
  [theme.breakpoints.up("lg")]: {
    marginBottom: theme.spacing(6) + "!important",
  },
  [theme.breakpoints.up("xl")]: {
    marginBottom: theme.spacing(6) + "!important",
  },
}))

const MainGridContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up("xs")]: {
    paddingTop: theme.spacing(4) + "!important",
    paddingBottom: theme.spacing(4) + "!important",
    paddingLeft: theme.spacing(2) + "!important",
    paddingRight: theme.spacing(2) + "!important",
  },
  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(4) + "!important",
    paddingBottom: theme.spacing(4) + "!important",
    paddingLeft: theme.spacing(3) + "!important",
    paddingRight: theme.spacing(3) + "!important",
  },
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(5) + "!important",
    paddingBottom: theme.spacing(5) + "!important",
    paddingLeft: theme.spacing(4) + "!important",
    paddingRight: theme.spacing(4) + "!important",
  },
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(6) + "!important",
    paddingBottom: theme.spacing(6) + "!important",
    paddingLeft: theme.spacing(4) + "!important",
    paddingRight: theme.spacing(4) + "!important",
  },
  [theme.breakpoints.up("xl")]: {
    paddingTop: theme.spacing(16) + "!important",
    paddingBottom: theme.spacing(16) + "!important",
    paddingLeft: theme.spacing(5) + "!important",
    paddingRight: theme.spacing(5) + "!important",
  },
}))

const TextWrapper = styled(motion.div)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  columnGap: theme.spacing(2) + "!important",
  paddingTop: theme.spacing(3) + "!important",
  paddingBottom: theme.spacing(3) + "!important",
  borderTop: `1px solid ${theme.palette.secondary.dark}`,
  backgroundColor: theme.palette.primary.main,
  width: "100%",
  [theme.breakpoints.down("md")]: { flexDirection: "column" },
}))

const ImgOne = styled(motion.div)<ImgOneProps>(({ theme, background }) => ({
  width: "100%",
  aspectRatio: "7 / 5",
  backgroundColor: "#121212",
  backgroundImage: `url(${background})`,
  backgroundSize: "102%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "50% 0%",

  [theme.breakpoints.down("md")]: {},
}))

const ImgTwo = styled(motion.div)<ImgTwoProps>(({ theme, background }) => ({
  width: "100%",
  aspectRatio: "7 / 5",
  backgroundColor: "red",
  backgroundImage: `url(${background})`,
  backgroundSize: "102%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",

  [theme.breakpoints.down("md")]: {},
}))

const AnimatedTypography = styled(Typography)`
  transition: color 0.5s ease; // Adjust the timing and easing as needed
`

const rowReveal = {
  visible: { opacity: 1, scale: 1, transformOrigin: "center top" },
  hidden: { opacity: 0, scale: 1, transformOrigin: "center top" },
}

const rotateAnimation = {
  expanded: { rotate: 180, transformOrigin: "0.5 0" }, // Rotate to 180 degrees with a custom transform origin
  collapsed: { rotate: 0, transformOrigin: "0.5 0" }, // Rotate back to 0 degrees with the same transform origin
}

function HistoryItem({
  item,
  inViewStatus,
  setRefInViewStatus,
}: {
  item: any
  inViewStatus: boolean
  setRefInViewStatus: (inView: boolean) => void
}) {
  const ref = useRef<HTMLDivElement>(null)
  const isInView = useInView(ref, { once: true }) // Now it's appropriately used in a top-level of a component

  useEffect(() => {
    setRefInViewStatus(isInView)
  }, [isInView])

  // Define a state to track whether the accordion is expanded
  const [expanded, setExpanded] = useState(false)

  // function formatDate(dateString: string) {
  //   const date = new Date(dateString)
  //   return date.getUTCFullYear().toString() // Returns only the year
  // }
  function formatDate(dateString: string) {
    const date = new Date(dateString)
    // Check if the date is 29 Mar 2024, return 'Present' if true
    if (date.toISOString().split("T")[0] === "2024-03-30") {
      return "Present"
    } else {
      return date.getUTCFullYear().toString() // Returns only the year
    }
  }

  const richTextContent = item.descRich
    ? documentToReactComponents(item.descRich.json)
    : null

  const [isHovered, setIsHovered] = useState(false)

  // Call the custom hook to get the current image size based on the screen size
  const imageSize = useResponsiveImageHistory()

  // Use the imageSize when constructing the image URL or setting image properties
  const imageUrl = item?.historyImgOne?.url + `?w=${imageSize}`

  return (
    <Grid
      key={item.order}
      container
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <CustomizedAccordion>
        <CustomizedAccordionSummary
          // expandIcon={<ExpandMoreIcon />}

          aria-controls="panel3-content"
          id="panel3-header"
          onClick={() => setExpanded(!expanded)} // Toggle the expanded state on click
        >
          <TextWrapper
            ref={ref}
            initial="hidden"
            animate={inViewStatus ? "visible" : "hidden"}
            variants={rowReveal}
            transition={{
              duration: 1,
              ease: [0.6, 0.01, 0.05, 0.95],
              delay: 0.2,
            }}
            // whileHover={{
            //   backgroundColor: theme.palette.text.primary,
            //   transition: { duration: 0.3 },
            // }}
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
            sx={{
              borderTop: expanded
                ? `2px solid ${theme.palette.secondary.dark}`
                : `1px solid ${theme.palette.secondary.dark}`,
            }}
          >
            <Grid item xs={12} md={12}>
              <Grid container spacing={0}>
                <Grid item xs={12} md={12} lg={2} xl={2}>
                  <AnimatedTypography
                    variant="h6"
                    fontWeight={500}
                    align="left"
                    color={"text.primary"}
                    // sx={{ textDecoration: isHovered ? "underline" : "none" }}
                    // color={isHovered ? "text.secondary" : "text.primary"}
                  >
                    {formatDate(item.startDate)}
                    <span>&nbsp;/&nbsp;</span>
                    {formatDate(item.endDate)}
                  </AnimatedTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={3}
                  xl={3}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    align="left"
                    color={"secondary.main"}
                  >
                    {item.companyName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4} xl={3}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    align="left"
                    color={"secondary.main"}
                  >
                    {item.role}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={3}
                  xl={4}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    align="left"
                    color={"secondary.main"}
                  >
                    {item.country}
                  </Typography>

                  <motion.div
                    initial={false}
                    animate={expanded ? "expanded" : "collapsed"} // Use the expanded state to determine animation
                    variants={rotateAnimation}
                    transition={{ duration: 0.3 }} // Adjust the duration as needed
                  >
                    <ExpandMoreIcon />
                  </motion.div>
                </Grid>
              </Grid>
            </Grid>
          </TextWrapper>
        </CustomizedAccordionSummary>
        <CustomizedAccordionDetails>
          <Grid
            container
            spacing={0}
            sx={{
              backgroundColor: "none",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={4}
              xl={3}
              xxl={2}
              sx={{
                paddingLeft: theme.spacing(0),
                order: {
                  xs: "3",
                  sm: "3",
                  md: "1",
                  lg: "1",
                  xl: "1",
                  xxl: "1",
                },
              }}
            >
              <Typography
                variant="body1"
                fontWeight={400}
                align="left"
                color={"secondary.main"}
                onClick={() => setExpanded(!expanded)}
                sx={(theme) => ({
                  paddingTop: { xs: "32px", md: "0px" },
                  "& p": {
                    [theme.breakpoints.up("xs")]: {
                      marginBlockStart: "0px !important",
                    },
                  },
                })}
              >
                {richTextContent}
              </Typography>
            </Grid>
            <Grid
              item
              xs={0}
              sm={0}
              md={1}
              lg={1}
              xl={2}
              xxl={3}
              sx={{
                backgroundColor: "none",
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },

                order: {
                  xs: "3",
                  sm: "3",
                  md: "3",
                  lg: "3",
                  xl: "3",
                  xxl: "3",
                },
              }}
            ></Grid>
            {item?.historyImgOne && (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={7}
                xl={7}
                xxl={7}
                sx={{
                  backgroundColor: "none",
                  order: {
                    xs: "1",
                    sm: "1",
                    md: "3",
                    lg: "3",
                    xl: "3",
                    xxl: "3",
                  },
                }}
              >
                <ImgOne background={imageUrl}></ImgOne>
              </Grid>
            )}
          </Grid>
        </CustomizedAccordionDetails>
      </CustomizedAccordion>
    </Grid>
  )
}

export default function History({ children }: HistoryProps) {
  const { loading, error, data } = useQuery(GET_HISTORY)

  // Move useState here, above the early returns
  const [inViewStatuses, setInViewStatuses] = useState<boolean[]>(
    data && data.historyCollection.items
      ? new Array(data.historyCollection.items.length).fill(false)
      : []
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  const historyItems = data.historyCollection.items

  return (
    <OuterWrapperDiv>
      <HeadlineWrapper>{children}</HeadlineWrapper>

      <MainGridContainer container>
        {historyItems.map((item: any, index: number) => (
          <HistoryItem
            key={item.order}
            item={item}
            inViewStatus={inViewStatuses[index]}
            setRefInViewStatus={(inView: boolean) =>
              setInViewStatuses((prev) => {
                const newStatuses = [...prev]
                newStatuses[index] = inView
                return newStatuses
              })
            }
          />
        ))}
      </MainGridContainer>
    </OuterWrapperDiv>
  )
}
