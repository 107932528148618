// queries.ts
import { gql } from "@apollo/client"

export const GET_HISTORY = gql`
  query GetHistory {
    historyCollection(order: order_ASC) {
      items {
        order
        startDate
        endDate
        companyName
        role
        country
        descRich {
          json
        }
        current
        historyImgOne {
          url
        }
        historyImgTwo {
          url
        }
      }
    }
  }
`
