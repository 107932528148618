import React, { ReactNode } from "react"
import Dialog from "@mui/material/Dialog"
import Fade from "@mui/material/Fade"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"

interface CustomDialogProps {
  open: boolean
  scroll?: "body" | "paper"
  children: ReactNode
  [x: string]: any // allows other props
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  open,
  scroll,
  children,
  ...props
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      maxWidth="xl"
      scroll={scroll || (isMobile ? "paper" : "body")}
      PaperProps={{
        style: {
          width: isMobile ? "100%" : "75%",
          backgroundColor: "transparent",
          overflow: "hidden",
          borderRadius: "0",
          boxShadow: "none",
        },
      }}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: {
            xs: "#ffffff", // mobile devices
            sm: "rgba(0, 0, 0, 0.87)", // devices larger than mobile
          },
        },
        "& .MuiDialogContent-root": {
          padding: 0,
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      }}
      TransitionComponent={Fade}
      {...props} // spreading other props at the end
    >
      {children}
    </Dialog>
  )
}

export default CustomDialog

// import React, { ReactNode, forwardRef } from "react"
// import Dialog from "@mui/material/Dialog"
// import Fade from "@mui/material/Fade"
// import useMediaQuery from "@mui/material/useMediaQuery"
// import { useTheme } from "@mui/material/styles"
// import CustomDialogContent from "./CustomDialogContent"

// interface CustomDialogProps {
//   open: boolean
//   scroll?: "body" | "paper"
//   children: ReactNode
//   [x: string]: any // allows other props
// }

// const CustomDialog = forwardRef<HTMLDivElement, CustomDialogProps>(
//   ({ open, scroll, children, ...props }, ref) => {
//     const theme = useTheme()
//     const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

//     return (
//       <Dialog
//         open={open}
//         fullScreen={isMobile}
//         maxWidth="xl"
//         scroll={scroll || (isMobile ? "paper" : "body")}
//         PaperProps={{
//           style: {
//             width: isMobile ? "100%" : "75%",
//             backgroundColor: "transparent",
//             overflow: "hidden",
//             borderRadius: "0",
//             boxShadow: "none",
//           },
//         }}
//         sx={{
//           "& .MuiBackdrop-root": {
//             backgroundColor: {
//               xs: "#ffffff", // mobile devices
//               sm: "rgba(0, 0, 0, 0.87)", // devices larger than mobile
//             },
//           },
//           "& .MuiDialogContent-root": {
//             padding: 0,
//             "&::-webkit-scrollbar": {
//               display: "none",
//             },
//           },
//         }}
//         TransitionComponent={Fade}
//         ref={ref} // Forwarding the ref to the Dialog component
//         {...props} // spreading other props at the end
//       >
//         {children}
//       </Dialog>
//     )
//   }
// )

// export default CustomDialog
