import { gql } from "@apollo/client"

const GET_INTRO = gql`
  query GetIntro {
    introCollection {
      items {
        sys {
          id
        }
        name
        surname
        header
        subheader
        subheaderTwo
        subheaderThree
        subheaderFour
        subheaderFive
        subheaderSix
        subheaderSeven
        subheaderEight
      }
    }
  }
`

export default GET_INTRO
