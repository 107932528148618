import React, { useState } from "react"
import { useQuery, gql } from "@apollo/client"
import GET_WORK_BY_ID from "../../Queries/getWorkById"
import {
  Container,
  Grid,
  Stack,
  styled,
  Typography,
  IconButton,
  Chip,
} from "@mui/material"
import { motion } from "framer-motion"
import { HeroScaleAnimation } from "../../Animation/AnimationOne"
import CloseIcon from "@mui/icons-material/Close"
import WorkDetailImage from "./WorkDetailComponents/WorkDetailImage"
import {
  getColorValue,
  getBackgroundColors,
} from "./WorkDetailComponents/colorUtils"
import theme from "../../theme"

const getFilenameFromUrl = (url: string | URL) => {
  const path = new URL(url).pathname
  const filename = path.substring(path.lastIndexOf("/") + 1)
  console.log("Extracted filename:", filename) // This will log the extracted filename
  return filename
}

interface WorkDetailProps {
  id: string
  onClose: () => void
  containerRef: React.RefObject<HTMLDivElement>
}

interface ProfileImgProps {
  background?: any
}

interface ImgScrollWrapperProps {
  hasPadding: boolean
}

interface ServiceItem {
  sys: { id: string }
  serviceList: string // Replace with the actual fields and types
}

// TypeScript interface definitions for getColorValue
interface WorkItem {
  imgOneColorRef: keyof ColorRefs
  primaryColor: string
  secondaryColor: string
  tertiaryColor: string
  serviceWorkCollection: {
    items: ServiceItem[]
  }
}

// TypeScript interface definitions for getColorValue
interface ColorRefs {
  primary: string
  secondary: string
  tertiary: string
}

const MainWrapper = styled(motion.div)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  paddingTop: theme.spacing(0),
  rowGap: theme.spacing(0),
  boxSizing: "border-box",
  backgroundColor: "#000000",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    // rowGap: theme.spacing(4),
    backgroundColor: "#ffffff",
  },
}))

const ProfileImg = styled(motion.div)<ProfileImgProps>(
  ({ theme, background }) => ({
    display: "flex",
    flexDirection: "column",
    alignContent: "start",
    alignItems: "center",
    boxSizing: "border-box",
    borderRadius: "30px",
    width: "44px",
    height: "44px",
    backgroundColor: "black",
    backgroundImage: `url(${background})`,
    backgroundSize: "80%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: { width: "40px", height: "40px" },
  })
)

const HeadWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignContent: "start",
  alignItems: "center",
  padding: theme.spacing(0),
  boxSizing: "border-box",
  backgroundColor: "none",
  width: "100%",
  color: "white",
  gap: theme.spacing(0),
  [theme.breakpoints.down("sm")]: {},
}))

const TitleWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignContent: "start",
  alignItems: "center",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  boxSizing: "border-box",
  backgroundColor: "none",
  width: "100%",
  color: "white",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const HeroWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  overflow: "hidden",
  position: "relative",
  [theme.breakpoints.down("sm")]: { height: "auto", marginBottom: "32px" },
}))

const FooterWrapper = styled(Grid)(({ theme }) => ({
  boxSizing: "border-box",
  backgroundColor: "white",
  color: "black",
  rowGap: theme.spacing(2),
  // Styles for xs and above
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(6),
  // Styles for sm and above
  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
  },
  // Styles for md and above
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(5),
  },
  // Styles for lg and above
  [theme.breakpoints.up("lg")]: {
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(17),
  },
  // Styles for xl and above
  [theme.breakpoints.up("xl")]: {
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(17),
  },
}))

const ProfileTitleWrapperFooter = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignContent: "start",
  alignItems: "center",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  boxSizing: "border-box",
  backgroundColor: "none",
  width: "100%",
  color: "white",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}))

const CloseDialog = styled(IconButton)(({ theme }) => ({
  height: "32px",
  width: "32px",
  backgroundColor: "none",
  position: "relative",
  color: theme.palette.text.secondary,

  [theme.breakpoints.down("sm")]: {
    backgroundColor: "white",
    position: "fixed",
    right: "16px",
    zIndex: 99,
    color: theme.palette.text.primary,
  },
}))

const WorkDetail: React.FC<WorkDetailProps> = ({
  id,
  onClose,
  containerRef,
}) => {
  const { data, loading, error } = useQuery(GET_WORK_BY_ID, {
    variables: { id },
  })

  const work = data?.workCollection.items[0]

  const [backgroundColors, setBackgroundColors] = useState({})

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error loading work detail.</div>

  // Utility function to determine if the URL points to a video
  const isVideoURL = (url: string) => {
    return [".mp4", ".webm"].some((ext) => url.endsWith(ext))
  }
  const heroMediaIsVideo = isVideoURL(work?.heroMedia?.url)

  // Make sure that work.imgOneColorRef contains a valid value ('primary', 'secondary', or 'tertiary')
  // If it's undefined or doesn't match, the function will log an error and return 'red'
  const imgOneBackgroundColor = getColorValue(work.imgOneColorRef, work)
  const workDetailBackgroundColors = getBackgroundColors(work)

  return (
    <div ref={containerRef}>
      <Container
        maxWidth={"xl"}
        disableGutters
        sx={{ backgroundColor: "none", position: "relative" }}
      >
        <HeadWrapper>
          <TitleWrapper>
            <ProfileImg
              sx={{ backgroundColor: work?.primaryColor }}
              variants={HeroScaleAnimation(0.3)}
              initial="heroinitial"
              animate="heroanimate"
              background={work?.profileImg?.url}
            ></ProfileImg>
            <motion.div
              variants={HeroScaleAnimation(0.5)}
              initial="heroinitial"
              animate="heroanimate"
            >
              <Typography
                variant="h5"
                fontWeight={500}
                sx={{
                  color: {
                    xs: theme.palette.text.primary,
                    sm: theme.palette.text.secondary,
                  },
                }}
              >
                {work?.title}
              </Typography>
            </motion.div>
          </TitleWrapper>
          <CloseDialog onClick={onClose}>
            <CloseIcon />
          </CloseDialog>
        </HeadWrapper>

        <HeroWrapper>
          {heroMediaIsVideo ? (
            <motion.video
              src={work?.heroMedia?.url}
              aria-label={work?.title}
              loop
              muted
              autoPlay
              playsInline
              variants={HeroScaleAnimation(0.7)}
              initial="heroinitial"
              animate="heroanimate"
              style={{ objectFit: "cover", width: "100%" }}
            />
          ) : (
            <motion.img
              src={work?.heroMedia?.url}
              alt={work?.title}
              variants={HeroScaleAnimation(0.7)}
              initial="heroinitial"
              animate="heroanimate"
              style={{
                width: "100%",
                height: "auto",
                // position: "absolute",
                // top: 0,
                // left: 0,
              }}
            />
          )}
        </HeroWrapper>

        <Grid
          container
          rowSpacing={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "none",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MainWrapper
            variants={HeroScaleAnimation(1)}
            initial="heroinitial"
            animate="heroanimate"
          >
            {/*  Image One */}
            {work.imgOne && (
              <WorkDetailImage
                imageUrl={work?.imgOne?.url}
                altText={"imgOne"}
                hasPadding={getFilenameFromUrl(work?.imgOne?.url).includes(
                  "screen"
                )}
                backgroundColor={workDetailBackgroundColors["imgOneColorRef"]}
                hasBottomBorder={work?.imgOneBorderBottom}
                work={work}
              ></WorkDetailImage>
            )}

            {work.imgTwo && (
              <WorkDetailImage
                imageUrl={work?.imgTwo?.url}
                altText={"imgTwo"}
                hasPadding={getFilenameFromUrl(work?.imgTwo?.url).includes(
                  "screen"
                )}
                backgroundColor={workDetailBackgroundColors["imgTwoColorRef"]}
                hasBottomBorder={work?.imgTwoBorderBottom}
                work={work}
              ></WorkDetailImage>
            )}
            {work.imgThree && (
              <WorkDetailImage
                imageUrl={work?.imgThree?.url}
                altText={"imgThree"}
                hasPadding={getFilenameFromUrl(work?.imgThree?.url).includes(
                  "screen"
                )}
                backgroundColor={workDetailBackgroundColors["imgThreeColorRef"]}
                hasBottomBorder={work?.imgThreeBorderBottom}
                work={work}
              ></WorkDetailImage>
            )}

            {work.imgFour && (
              <WorkDetailImage
                imageUrl={work?.imgFour?.url}
                altText={"imgFour"}
                hasPadding={getFilenameFromUrl(work?.imgFour?.url).includes(
                  "screen"
                )}
                backgroundColor={workDetailBackgroundColors["imgFourColorRef"]}
                hasBottomBorder={work?.imgFourBorderBottom}
                work={work}
              ></WorkDetailImage>
            )}
            {work.imgFive && (
              <WorkDetailImage
                imageUrl={work?.imgFive?.url}
                altText={"imgFive"}
                hasPadding={getFilenameFromUrl(work?.imgFive?.url).includes(
                  "screen"
                )}
                backgroundColor={workDetailBackgroundColors["imgFiveColorRef"]}
                hasBottomBorder={work?.imgFiveBorderBottom}
                work={work}
              ></WorkDetailImage>
            )}
            {work.imgSix && (
              <WorkDetailImage
                imageUrl={work?.imgSix?.url}
                altText={"imgSix"}
                hasPadding={getFilenameFromUrl(work?.imgSix?.url).includes(
                  "screen"
                )}
                backgroundColor={workDetailBackgroundColors["imgSixColorRef"]}
                hasBottomBorder={work?.imgSixBorderBottom}
                work={work}
              ></WorkDetailImage>
            )}
            {work.imgSeven && (
              <WorkDetailImage
                imageUrl={work?.imgSeven?.url}
                altText={"imgSeven"}
                hasPadding={getFilenameFromUrl(work?.imgSeven?.url).includes(
                  "screen"
                )}
                backgroundColor={workDetailBackgroundColors["imgSevenColorRef"]}
                hasBottomBorder={work?.imgSevenBorderBottom}
                work={work}
              ></WorkDetailImage>
            )}
            {work.imgEight && (
              <WorkDetailImage
                imageUrl={work?.imgEight?.url}
                altText={"imgEight"}
                hasPadding={getFilenameFromUrl(work?.imgEight?.url).includes(
                  "screen"
                )}
                backgroundColor={workDetailBackgroundColors["imgEightColorRef"]}
                hasBottomBorder={work?.imgEightBorderBottom}
                work={work}
              ></WorkDetailImage>
            )}
            {work.imgNine && (
              <WorkDetailImage
                imageUrl={work?.imgNine?.url}
                altText={"imgNine"}
                hasPadding={getFilenameFromUrl(work?.imgNine?.url).includes(
                  "screen"
                )}
                backgroundColor={workDetailBackgroundColors["imgNineColorRef"]}
                hasBottomBorder={work?.imgNineBorderBottom}
                work={work}
              ></WorkDetailImage>
            )}
            {work.imgTen && (
              <WorkDetailImage
                imageUrl={work?.imgTen?.url}
                altText={"imgTen"}
                hasPadding={getFilenameFromUrl(work?.imgTen?.url).includes(
                  "screen"
                )}
                backgroundColor={workDetailBackgroundColors["imgTenColorRef"]}
                hasBottomBorder={work?.imgTenBorderBottom}
                work={work}
              ></WorkDetailImage>
            )}
            {work.imgEleven && (
              <WorkDetailImage
                imageUrl={work?.imgEleven?.url}
                altText={"imgEleven"}
                hasPadding={getFilenameFromUrl(work?.imgEleven?.url).includes(
                  "screen"
                )}
                backgroundColor={
                  workDetailBackgroundColors["imgElevenColorRef"]
                }
                hasBottomBorder={work?.imgElevenBorderBottom}
                work={work}
              ></WorkDetailImage>
            )}

            {work.imgTwelve && (
              <WorkDetailImage
                imageUrl={work?.imgTwelve?.url}
                altText={"imgTwelve"}
                hasPadding={getFilenameFromUrl(work?.imgTwelve?.url).includes(
                  "screen"
                )}
                backgroundColor={workDetailBackgroundColors["imgTenColorRef"]}
                hasBottomBorder={work?.imgTenBorderBottom}
                work={work}
              ></WorkDetailImage>
            )}
          </MainWrapper>
        </Grid>
        {/* <FooterWrapper> */}
        <FooterWrapper container columnSpacing={8}>
          <Grid item xs={12} md={12}>
            <ProfileTitleWrapperFooter>
              <ProfileImg
                sx={{ backgroundColor: work?.primaryColor }}
                variants={HeroScaleAnimation(0.3)}
                initial="heroinitial"
                animate="heroanimate"
                background={work?.profileImg?.url}
              ></ProfileImg>
              <Typography
                variant="h5"
                fontWeight={"500"}
                textAlign={"center"}
                sx={{ color: theme.palette.text.primary }}
              >
                {work.title}
              </Typography>
            </ProfileTitleWrapperFooter>
          </Grid>

          <Grid item xs={12} sm={12} md={10} lg={6} xl={6}>
            <Typography
              variant="body1"
              fontWeight={"400"}
              textAlign={"left"}
              sx={{ color: theme.palette.text.primary }}
              mb={3}
            >
              {work.description}
            </Typography>
            {work.roleOne && (
              <Typography
                variant="body1"
                fontWeight={"400"}
                textAlign={"left"}
                mb={3}
              >
                {work.roleOne}
              </Typography>
            )}
            {work.roleTwo && (
              <Typography
                variant="body1"
                fontWeight={"400"}
                textAlign={"left"}
                sx={{ color: theme.palette.text.primary }}
                mb={6}
              >
                {work.roleTwo}
              </Typography>
            )}
            {work.serviceWorkCollection.items.map(
              (service: ServiceItem, index: number) => (
                <Chip
                  key={service.sys.id}
                  label={service.serviceList}
                  variant="outlined"
                  sx={{
                    marginRight: theme.spacing(1),
                    marginBottom: theme.spacing(1.5),
                  }}
                />
              )
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              variant="body1"
              onClick={onClose}
              sx={{
                cursor: "pointer",
                color: theme.palette.text.primary,
                textDecoration: "underline",
                textUnderlineOffset: "3px",
              }}
            >
              Close
            </Typography>
          </Grid>
        </FooterWrapper>
        {/* </FooterWrapper> */}
      </Container>
    </div>
  )
}

export default WorkDetail
