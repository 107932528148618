// colorUtils.ts

// Define the interface for color references.
export interface ColorRefs {
  primary: string
  secondary: string
  tertiary: string
}

// Define the interface for a work item.
export interface WorkItem {
  [key: string]: keyof ColorRefs | string // This index signature should cover all the string properties
  primaryColor: string
  secondaryColor: string
  tertiaryColor: string
}

export const getColorValue = (
  colorRef: string, // Accept a string type for more flexibility
  workItem: WorkItem
): string => {
  // We expect colorRef to be 'primaryColor', 'secondaryColor', or 'tertiaryColor',
  // so we remove the 'Color' part to match the keys in our ColorRefs object.
  const validColorRef = colorRef.replace("Color", "") as keyof ColorRefs

  // Now we define our color references.
  const colors: ColorRefs = {
    primary: workItem.primaryColor,
    secondary: workItem.secondaryColor,
    tertiary: workItem.tertiaryColor,
  }

  // Check if the transformed colorRef is a valid key in the colors object
  if (!colors[validColorRef]) {
    console.error(
      `Invalid colorRef '${colorRef}' provided. Valid keys are 'primaryColor', 'secondaryColor', 'tertiaryColor'.`
    )
    return "red" // Fallback color
  }

  // Use the validColorRef to get the corresponding color value.
  const colorValue = colors[validColorRef]
  console.log(`Resolved color for '${colorRef}':`, colorValue)

  console.log(`colorRef: ${colorRef}, validColorRef: ${validColorRef}`)
  console.log(`Colors object:`, colors)
  console.log(`Color value being returned: ${colors[validColorRef]}`)

  return colorValue
}

export const getBackgroundColors = (workItem: WorkItem) => {
  console.log("Received workItem:", workItem) // Log the entire workItem for inspection

  let backgroundColors: Record<string, string> = {}
  // Loop over each property of the workItem
  Object.keys(workItem).forEach((key) => {
    if (key.endsWith("ColorRef")) {
      // Log each key being processed
      console.log(`Processing key: ${key}`)
      // The value for each imgXColorRef should be a string like 'primaryColor', 'secondaryColor', or 'tertiaryColor'
      const colorRef = workItem[key] as keyof WorkItem

      // Check if the color reference actually points to a color property in workItem
      if (typeof workItem[colorRef] === "string") {
        backgroundColors[key] = workItem[colorRef]
        // Log the successful mapping
        console.log(`Mapped ${key} to color: ${backgroundColors[key]}`)
      } else {
        console.error(`Invalid color reference for key '${key}': ${colorRef}`)
        backgroundColors[key] = "red" // Fallback color
      }
    }
  })
  console.log("Final backgroundColors object:", backgroundColors)
  return backgroundColors
}
