import React, { useEffect, useRef } from "react"
import { motion, useInView } from "framer-motion"
import {
  Grid,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { red } from "@mui/material/colors"

const MainWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  paddingTop: theme.spacing(0),
  rowGap: theme.spacing(2),
  boxSizing: "border-box",
  cursor: "pointer",
  overflow: "hidden",
  backgroundColor: "none",
  [theme.breakpoints.down("sm")]: {},
}))

const ImageWrapper = styled(motion.div)(({ theme }) => ({
  overflow: "hidden",
  backgroundColor: "none",
  position: "relative",
  [theme.breakpoints.down("sm")]: {},
}))

const TextWrapper = styled(motion.div)(({ theme }) => ({
  overflow: "hidden",
  backgroundColor: "none",
  position: "relative",
  [theme.breakpoints.down("sm")]: {},
}))

const ImageWipe = styled(motion.div)(({ theme }) => ({
  overflow: "hidden",
  backgroundColor: theme.palette.secondary.main,
  position: "absolute",
  zIndex: 10,
  height: "100%",
  width: "100%",
  [theme.breakpoints.down("sm")]: {},
}))

type ServiceWorkType = {
  sys: {
    id: string
  }
  serviceList: string
}

type WorkType = {
  id: string
  title: string
  description?: string // Assuming description might be optional
  heroMedia: {
    url: string
  }
  serviceWorkCollection: {
    items: ServiceWorkType[]
  }
}
type WorkGridThumbnailProps = {
  work: WorkType // Use the updated WorkType here
  onClick: (work: WorkType) => void
}

const imgScale = {
  visible: { opacity: 1, scale: 1, transformOrigin: "center top" },
  hidden: { opacity: 0.3, scale: 1.2, transformOrigin: "center top" },
}

const containerScale = {
  open: { opacity: 1, scale: 1, transformOrigin: "center top" },
  closed: { opacity: 1, scale: 1, transformOrigin: "center top" },
}

const imageWipeVarients = {
  start: { scaleY: 1, transformOrigin: "top" },
  end: { scaleY: 0, transformOrigin: "top" },
}

const textReveal = {
  visible: { opacity: 1, y: 0, transformOrigin: "center top" },
  hidden: { opacity: 0, y: 10, transformOrigin: "center top" },
}

const WorkGridThumbnail: React.FC<WorkGridThumbnailProps> = ({
  work,
  onClick,
}) => {
  const isVideo = [".mp4", ".webm"].some((ext) =>
    work.heroMedia.url.endsWith(ext)
  )
  const theme = useTheme()

  const isXSmall = useMediaQuery(theme.breakpoints.only("xs"))
  const isSmall = useMediaQuery(theme.breakpoints.only("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.only("md"))
  const isLarge = useMediaQuery(theme.breakpoints.only("lg"))
  const isXLarge = useMediaQuery(theme.breakpoints.only("xl"))

  // Define image sizes for different screen breakpoints
  const imageSizeMap = {
    xsmall: 500,
    small: 900,
    medium: 450, // Adjusted for medium screens
    large: 1103,
    xlarge: 1105,
  }

  const getMarginForInView = () => {
    if (isXSmall) return "0px 0px -100px 0px" // Example margin for xs
    if (isSmall) return "0px 0px -200px 0px" // Example margin for sm
    if (isMedium) return "0px 0px -300px 0px" // Example margin for md
    if (isLarge) return "0px 0px -300px 0px" // Example margin for lg
    if (isXLarge) return "0px 0px -300px 0px" // Example margin for xl
    return "0px 0px -400px 0px" // Default margin
  }

  const getMarginForTextInView = () => {
    if (isXSmall) return "0px 0px -100px 0px" // Example margin for xs
    if (isSmall) return "0px 0px -200px 0px" // Example margin for sm
    if (isMedium) return "0px 0px -300px 0px" // Example margin for md
    if (isLarge) return "0px 0px -500px 0px" // Example margin for lg
    if (isXLarge) return "0px 0px -500px 0px" // Example margin for xl
    return "0px 0px -400px 0px" // Default margin
  }

  // Determine the selected screen size for images
  const screenSize = isXSmall
    ? "xsmall"
    : isSmall
    ? "small"
    : isMedium
    ? "medium"
    : isLarge
    ? "large"
    : "xlarge"

  // Construct the imageUrl with the selected size
  const imageUrl = `${work.heroMedia.url}?w=${imageSizeMap[screenSize]}`

  const ref = useRef(null)
  const isInView = useInView(ref, { margin: getMarginForInView() })
  const titleIsInView = useInView(ref, { margin: getMarginForTextInView() })

  useEffect(() => {
    console.log("Element is in view: ", isInView)
  }, [isInView])

  const firstTwoServices =
    work.serviceWorkCollection?.items
      .slice(0, 2)
      .map((service: ServiceWorkType) => service.serviceList) || []

  return (
    <Grid item xs={12} md={12} onClick={() => onClick(work)}>
      <MainWrapper>
        <ImageWrapper
          ref={ref}
          initial="hidden" // start in the hidden state
          animate={isInView ? "open" : "closed"} // animate to visible when in view
          variants={containerScale} // the animation variants you defined
          transition={{ duration: 2.35, ease: [0.6, 0.01, 0.05, 0.95] }}
        >
          <ImageWipe
            ref={ref}
            initial="start" // start in the hidden state
            animate={isInView ? "end" : "start"} // animate to visible when in view
            variants={imageWipeVarients} // the animation variants you defined
            transition={{ duration: 0.5, ease: [0.01, 0.01, 0.01, 0.95] }}
          ></ImageWipe>
          {isVideo ? (
            <motion.video
              ref={ref}
              initial="hidden" // start in the hidden state
              animate={isInView ? "visible" : "hidden"} // animate to visible when in view
              variants={imgScale} // the animation variants you defined
              transition={{ duration: 1, ease: [0.6, 0.01, 0.05, 0.95] }}
              className="video-thumb"
              src={imageUrl}
              // src={work.heroMedia.url}
              aria-label={work.title}
              loop
              muted
              autoPlay
              playsInline
              whileHover={{ scale: 1.05, transition: { duration: 3 } }}
              style={{ objectFit: "cover", width: "100%" }}
            />
          ) : (
            <motion.img
              ref={ref}
              initial="hidden" // start in the hidden state
              animate={isInView ? "visible" : "hidden"} // animate to visible when in view
              variants={imgScale} // the animation variants you defined
              transition={{ duration: 1, ease: [0.6, 0.01, 0.05, 0.95] }}
              className="image-thumb"
              src={imageUrl}
              // src={work.heroMedia.url}
              alt={work.title}
              whileHover={{ scale: 1.05, transition: { duration: 3 } }}
              style={{ width: "100%", height: "auto" }} // Adjusted style here
            />
          )}
        </ImageWrapper>
        <TextWrapper
          initial="hidden" // start in the hidden state
          animate={isInView ? "visible" : "hidden"} // animate to visible when in view
          variants={textReveal} // the animation variants you defined
          transition={{
            delay: 0.5,
            duration: 0.5,
            ease: [0.6, 0.01, 0.05, 0.95],
          }}
        >
          <Typography variant="h6" color={"primary"} align="left">
            {work.title}
          </Typography>
          {/* Render the first two services */}
          {firstTwoServices.map((service, index) => (
            <Typography
              key={index}
              variant="body2"
              color="textSecondary"
              align="left"
            >
              {service}
            </Typography>
          ))}
        </TextWrapper>
      </MainWrapper>
    </Grid>
  )
}

export default WorkGridThumbnail
