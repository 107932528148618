import { gql } from "@apollo/client"

const GET_WORK_BY_ID = gql`
  query WorkById($id: String!) {
    workCollection(where: { sys: { id: $id } }, limit: 1) {
      items {
        order
        title
        description
        roleOne
        roleTwo
        serviceWorkCollection {
          items {
            sys {
              id
            }
            serviceList
          }
        }
        profileImg {
          url
        }
        heroMedia {
          url
        }
        imgOne {
          url
        }
        imgOneBorderBottom
        imgOneColorRef

        imgTwo {
          url
        }
        imgTwoBorderBottom
        imgTwoColorRef
        imgThree {
          url
        }
        imgThreeBorderBottom
        imgThreeColorRef
        imgFour {
          url
        }
        imgFourBorderBottom
        imgFourColorRef
        imgFive {
          url
        }
        imgFiveBorderBottom
        imgFiveColorRef
        imgSix {
          url
        }
        imgSixBorderBottom
        imgSixColorRef
        imgSeven {
          url
        }
        imgSevenBorderBottom
        imgSevenColorRef
        imgEight {
          url
        }
        imgEightBorderBottom
        imgEightColorRef
        imgNine {
          url
        }
        imgNineBorderBottom
        imgNineColorRef
        imgTen {
          url
        }
        imgTenBorderBottom
        imgTenColorRef
        imgEleven {
          url
        }
        imgElevenBorderBottom
        imgElevenColorRef
        imgTwelve {
          url
        }
        imgTwelveBorderBottom
        imgTwelveColorRef

        primaryColor
        secondaryColor
        tertiaryColor
      }
    }
  }
`

export default GET_WORK_BY_ID
