import React from "react"
import { Grid, Typography, styled, useTheme } from "@mui/material"
import { motion } from "framer-motion"
import { useQuery } from "@apollo/client"
import GET_FOOT from "../../Queries/getFoot"
import theme from "../../theme"

interface DanImgProps {
  background?: any
}

type FootType = {
  id: string
  headerOne: string
  headerTwo: string
  headerThree: string
  headerFour: string
  bodyOne: string
  bodyTwo: string
  bodyThree: string
  bodyFour: string
  footImg: {
    url: string
  }
}

const MainContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  [theme.breakpoints.up("xs")]: {
    paddingTop: theme.spacing(10) + "!important",
    paddingBottom: theme.spacing(10) + "!important",
    paddingLeft: theme.spacing(2) + "!important",
    paddingRight: theme.spacing(2) + "!important",
  },
  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(4) + "!important",
    paddingBottom: theme.spacing(8) + "!important",
    paddingLeft: theme.spacing(3) + "!important",
    paddingRight: theme.spacing(3) + "!important",
  },
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(5) + "!important",
    paddingBottom: theme.spacing(5) + "!important",
    paddingLeft: theme.spacing(4) + "!important",
    paddingRight: theme.spacing(4) + "!important",
  },
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(5) + "!important",
    paddingBottom: theme.spacing(5) + "!important",
    paddingLeft: theme.spacing(4) + "!important",
    paddingRight: theme.spacing(4) + "!important",
  },
  [theme.breakpoints.up("xl")]: {
    paddingTop: theme.spacing(20) + "!important",
    paddingBottom: theme.spacing(20) + "!important",
    paddingLeft: theme.spacing(5) + "!important",
    paddingRight: theme.spacing(5) + "!important",
  },
}))

const BodyContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  [theme.breakpoints.up("xs")]: {
    paddingTop: theme.spacing(6),
  },
}))

const SpacerGridItem = styled(motion(Grid))(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "none",
  },
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
  [theme.breakpoints.up("lg")]: {
    display: "flex",
    borderRight: `2px solid ${theme.palette.primary.main}`,
    paddingTop: theme.spacing(0) + "!important",
  },
  [theme.breakpoints.up("xl")]: {
    display: "flex",
    borderRight: `2px solid ${theme.palette.primary.main}`,
    paddingTop: theme.spacing(0) + "!important",
  },
}))

const DanImgGridItem = styled(motion(Grid))(({ theme }) => ({}))

const DanImg = styled(motion.div)<DanImgProps>(({ theme, background }) => ({
  display: "flex",
  flexDirection: "column",
  alignContent: "flex-start",
  alignItems: "center",
  boxSizing: "border-box",
  borderRadius: "50%",
  width: "100%", // Make width responsive
  height: "auto", // Adjust height automatically based on the aspect ratio
  aspectRatio: "1 / 1", // Ensure the width and height are always equal
  backgroundColor: "black",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover", // Use "cover" to ensure the background image covers the circle
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingLeft: theme.spacing(0) + "!important",
  paddingTop: theme.spacing(0) + "!important",
  [theme.breakpoints.up("xs")]: {
    width: "100%", // Maintain responsiveness on small screens
    height: "auto", // Adjust height automatically
    marginBottom: theme.spacing(4) + "!important",
  },
  [theme.breakpoints.up("sm")]: {
    width: "100%", // Maintain responsiveness on small screens
    height: "auto", // Adjust height automatically
    marginBottom: theme.spacing(4) + "!important",
  },
  [theme.breakpoints.up("md")]: {
    width: "100%", // Maintain responsiveness on small screens
    height: "auto", // Adjust height automatically
    marginBottom: theme.spacing(4) + "!important",
  },
  [theme.breakpoints.up("xxl")]: {
    width: "50%", // Example: Adjust width for XXL screens
    height: "auto", // You can adjust the height as needed
    // Add any additional styles you want for XXL screens
  },
}))

const MailLink = styled("a")(({ theme }) => ({
  color: "inherit",
  textDecoration: "underline",
  textDecorationThickness: "0.25rem", // Default
  textUnderlineOffset: "0.5rem",
  [theme.breakpoints.up("xs")]: {
    textDecorationThickness: "2px", // Small devices
  },
  [theme.breakpoints.up("md")]: {
    textDecorationThickness: "3px", // Medium devices
  },
  [theme.breakpoints.up("lg")]: {
    textDecorationThickness: "3px", // Large devices
  },
}))

export default function Foot() {
  const { loading, error, data } = useQuery(GET_FOOT)

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  const foots: FootType[] = data?.footCollection.items.map((item: any) => ({
    id: item.sys.id,
    headerOne: item.headerOne,
    headerTwo: item.headerTwo,
    headerThree: item.headerThree,
    headerFour: item.headerFour,
    bodyOne: item.bodyOne,
    bodyTwo: item.bodyTwo,
    bodyThree: item.bodyThree,
    bodyFour: item.bodyFour,
    footImg: {
      url: `${item.footImg.url}`,
    },
  }))

  return (
    <MainContainer container direction="row">
      {foots.map((foot) => (
        <Grid key={foot.id} item xs={12} container spacing={2}>
          {/* Text Section */}
          <Grid item xs={12} lg={12}>
            {/* header Section */}
            <Grid
              container
              direction="row"
              sx={{
                mb: { xs: 4, md: 4, lg: 12 },
              }}
            >
              <Grid item xs={12} lg={8}>
                <Typography variant="h2" color="primary" align="left">
                  {foot.headerOne}
                </Typography>

                <Typography variant="h2" color="primary" align="left">
                  {foot.headerTwo}
                </Typography>

                <Typography
                  variant="h2"
                  color="primary"
                  align="left"
                  sx={{
                    mb: { xs: 0.25, md: 0.5, lg: 1 },
                  }}
                >
                  <MailLink href="mailto:danbillingham@gmail.com">
                    {foot.headerThree}
                  </MailLink>
                </Typography>
                <Typography variant="h2" color="primary" align="left">
                  <a
                    href={`tel:${foot.headerFour}`}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    {foot.headerFour}
                  </a>
                </Typography>

                {/* <Typography variant="h2" color="primary" align="left">
                  {foot.headerFour}
                </Typography> */}
              </Grid>
            </Grid>
            {/* Body wrapper */}
            <Grid container direction="row" columnSpacing={8} rowSpacing={0}>
              {/* Body Column */}
              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                xl={3}
                sx={{ order: { xs: 2, md: 1 }, mb: { xs: 2, md: 4, lg: 0 } }}
              >
                <Typography variant="h6" color="primary" align="left">
                  {foot.bodyOne}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                xl={3}
                sx={{ order: { xs: 3, md: 2 }, mb: { xs: 2, md: 4, lg: 0 } }}
              >
                <Typography variant="h6" color="primary" align="left">
                  {foot.bodyTwo}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                xl={3}
                sx={{ order: { xs: 4, md: 3 }, mb: { xs: 2, md: 4, lg: 0 } }}
              >
                <Typography variant="h6" color="primary" align="left">
                  {foot.bodyThree}
                </Typography>
              </Grid>
              {/* Spacer Section */}
              <SpacerGridItem
                item
                xs={0}
                md={0}
                lg={1}
                xl={1}
                sx={{ order: { xs: 5, md: 4 } }}
              ></SpacerGridItem>
              {/* Image Section */}
              <DanImgGridItem
                item
                xs={6}
                md={3}
                lg={2}
                xl={2}
                sx={{ order: { xs: 1, md: 5 } }}
              >
                <DanImg background={foot?.footImg?.url}></DanImg>
              </DanImgGridItem>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </MainContainer>
  )
}
