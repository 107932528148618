import { Grid, styled, useMediaQuery, useTheme } from "@mui/material"

import { motion } from "framer-motion"

interface ImageDisplayProps {
  imageUrl: string
  altText: string
  hasPadding: boolean
  hasBottomBorder: boolean
  backgroundColor?: string
  work: { heroMedia: { url: string } } // Define the work prop here
}

interface ImgScrollWrapperProps {
  hasPadding: boolean
  hasBottomBorder: boolean
}

const ImgScrollWrapper = styled("div")<ImgScrollWrapperProps>(
  ({ theme, hasPadding, hasBottomBorder }) => ({
    width: "100%",
    overflow: "hidden",
    position: "relative",
    paddingTop: hasPadding ? theme.spacing(16) : 0,
    paddingBottom: hasPadding ? theme.spacing(16) : 0,
    display: "flex", // Ensure the container uses flexbox
    alignItems: "stretch", // Stretch items to fill the container
    [theme.breakpoints.down("lg")]: {
      paddingTop: hasPadding ? theme.spacing(10) : 0,
      paddingBottom: hasPadding ? theme.spacing(10) : 0,
      borderBottom: hasBottomBorder ? "32px solid white" : "none",
    },

    [theme.breakpoints.down("md")]: {
      paddingTop: hasPadding ? theme.spacing(6) : 0,
      paddingBottom: hasPadding ? theme.spacing(6) : 0,
      borderBottom: hasBottomBorder ? "32px solid white" : "none",
    },
  })
)

const WorkDetailImage: React.FC<ImageDisplayProps> = ({
  imageUrl,
  altText,
  hasPadding,
  hasBottomBorder,
  backgroundColor,
  work,
}) => {
  const theme = useTheme()
  const isXSmall = useMediaQuery(theme.breakpoints.only("xs"))
  const isSmall = useMediaQuery(theme.breakpoints.only("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.only("md"))
  const isLarge = useMediaQuery(theme.breakpoints.only("lg"))
  const isXLarge = useMediaQuery(theme.breakpoints.only("xl"))

  // Define image sizes for different screen breakpoints
  const imageSizeMap = {
    xsmall: 750,
    small: 750,
    medium: 850, // Adjusted for medium screens
    large: 1103,
    xlarge: 1600,
  }

  // Determine the selected screen size for images
  const screenSize = isXSmall
    ? "xsmall"
    : isSmall
    ? "small"
    : isMedium
    ? "medium"
    : isLarge
    ? "large"
    : "xlarge"

  // Construct the imageUrl with the selected size
  const resizedImageUrl = `${imageUrl}?w=${imageSizeMap[screenSize]}`

  return (
    <Grid item lg={12} sx={{ width: "100%", backgroundColor }}>
      <ImgScrollWrapper
        hasPadding={hasPadding}
        hasBottomBorder={hasBottomBorder}
      >
        <motion.img
          src={resizedImageUrl}
          alt={altText}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </ImgScrollWrapper>
    </Grid>
  )
}

export default WorkDetailImage
